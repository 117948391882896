.react-datepicker__input-container {
  margin-top: 5px;
}

.react-datepicker__input-container input {
  margin-top: 20px;
  background-color: rgba(239, 241, 249, 1);
  border: 1px solid #ced4da;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  height: 16px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  margin-right: 30px;
  min-width: 100%;
  min-height: 35px;
}
